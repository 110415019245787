import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "page-loader flex-column text-center",
  style: {"display":"block","padding-top":"40vh"}
}
const _hoisted_2 = ["src"]
const _hoisted_3 = { class: "text-muted fs-7 fw-bold ms-5 mt-2" }
const _hoisted_4 = { class: "text-capitalize" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("img", {
      alt: "Logo",
      class: "h-30px",
      src: _ctx.loaderLogo
    }, null, 8, _hoisted_2),
    _createElementVNode("div", _hoisted_3, [
      _cache[1] || (_cache[1] = _createTextVNode(" Please wait, switching to ")),
      _createElementVNode("strong", null, [
        _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.switchTo), 1),
        _cache[0] || (_cache[0] = _createTextVNode(" SKYE"))
      ]),
      _cache[2] || (_cache[2] = _createTextVNode(".   ")),
      _cache[3] || (_cache[3] = _createElementVNode("span", {
        class: "spinner-border spinner-border-sm",
        role: "status"
      }, [
        _createElementVNode("span", { class: "visually-hidden" }, "Loading...")
      ], -1))
    ])
  ]))
}